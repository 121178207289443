.container {
  @apply my-8 flex items-center;

  @screen sm {
    @apply h-5/6;
  }
}

.inner {
  @apply mx-auto text-center py-20 bg-white/70 w-full max-w-5xl;

  h2 {
    @apply mb-3;
  }

  @screen sm {
    @apply px-28 rounded-lg w-auto;
  }
}
