.signinButton {
  @apply outline-none;

  @screen lg {
    @apply rounded-md font-semibold py-0.5 px-2 w-20 border-white/40 text-white/90;

    &:hover {
      @apply border-sky-100 text-sky-100;
    }
  }
}

.exit {
  button {
    @apply flex justify-center items-center px-2 text-white text-sm font-normal tracking-wider;

    &:hover {
      @apply text-sky-50;
    }

    &:focus {
      @apply outline-none;
    }
  }
}

.closeIcon {
  @apply rounded-full bg-sky-600 ml-3 w-7 h-7 flex justify-center items-center;

  svg {
    @apply text-base font-semibold;
  }
}
