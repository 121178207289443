.container {
  @apply relative bg-stone-50 overflow-y-visible h-full flex flex-col;
}

.content {
  @apply relative flex bottom-6 left-0 right-0 top-0 flex-row;
}

.main {
  @apply flex flex-col flex-grow min-w-0 min-h-0 overflow-hidden;
}

.withClouds {
  @apply bg-[url("/images/Hosted-Dolt-Clouds.jpg")] bg-no-repeat bg-cover bg-stone-50 outline-0 bg-right;
  min-height: calc(100vh - 8.5rem);
}
