.openButton {
  @apply flex items-center relative ml-10 text-base;

  svg {
    @apply ml-2;
  }
}

.dropdown {
  @apply z-50 absolute bg-white rounded border border-stone-100 text-space-600 right-[3.75rem] w-40;

  a,
  button {
    @apply text-button-1 font-semibold mx-0 my-0;
  }

  > div {
    @apply px-3 py-1.5;
  }

  > div:not(:last-of-type) {
    @apply border-b border-stone-100 w-full;
  }
}
