.loading {
  @apply py-2 text-2xl font-semibold mx-auto italic;

  @screen md {
    @apply ml-2 italic font-medium m-0 py-1 text-sm;

    > span {
      @apply ml-5;
    }
  }
}

.pill {
  @apply w-56 bg-transparent border border-white border-opacity-40 shadow-none;

  &:hover {
    @apply bg-transparent border-sky-300 text-sky-300;
  }
}
