.container {
  @apply bottom-0 text-white w-full h-[15vh] flex justify-around px-3 mx-auto bg-stone-50;

  @screen md {
    @apply h-[6vh];
  }
}

.bottom {
  @apply mx-auto flex flex-col items-center justify-center text-stone-100;

  > * {
    @apply ml-6 text-space-600;
  }

  a {
    @apply text-space-600;
  }

  @screen md {
    @apply flex-row gap-10;
  }
}

.copyright {
  @apply text-stone-500 pt-4;

  @screen md {
    @apply pt-0;
  }
}

.links {
  @apply flex flex-row items-center justify-center gap-11;
}
